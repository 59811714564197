<template>
  <div>    

    <div class="its">
      <div 
        v-for="(o, k) in [0,1,2,3,4,5,6,7,8,9,10]"
        :key="k"
        class="itm skl"
        />              
    </div>
  
  </div>
</template>


<script>

export default {
  components: {    
    
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.its{
  display: flex;
  flex-wrap: nowrap;  
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 10px;  
} 
.its .itm{
  height: 25px;
  width: 100px;
  flex-shrink: 0;
  border-radius: 30px;
  margin-right: 5px;
}


</style>
