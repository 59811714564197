<template>
 
 <GeneralHeroSearch /> 
 
  <div class="pb index_page grid_width">
    <!-- <InfinityScroll/> -->


    <template v-if="!feed_data?.items_tags">
      <SkFeedTag />
    </template>
    <template v-else>
      <FeedTags :list="feed_data?.items_tags" :selected="selected_filter" @choose="chooseTag" />
    </template>
  

    <template v-if="is_loading && !feed_data?.items_feed?.length">
      <SkFeed />
      <SkFeed />
      <SkFeed />
      <SkFeed /> 
    </template>
    <template v-else>

      
 
      <div v-if="feed_data?.items_feed" class="feed_items">
        <div v-for="(feed, feed_key) in feed_data?.items_feed" :key="feed_key" :data="feed" class="row_item"
          :data-type-id="feed.id">

          
          <div class="header" v-if="feed.lng_key || feed.name">

            <template
              v-if="Array('seed', 'brandseed', 'tent', 'brandtent', 'nutrient', 'brandnutrient', 'lamp', 'brandlamp', 'user').indexOf(feed.type) >= 0">
              <div class="ic"><img :alt="feed.lng_key ? $t(feed.lng_key) : feed.name" :src="feed.avatar"></div>
            </template>
            <!-- <template v-else-if="feed.type == 'locale'">
              <div class="ic"><img :alt="feed.name"
                  :src="'/images/locales/' + feed.id.replace('locale_', '') + '_2x.png'"></div>
            </template> -->
            <template v-else-if="feed.type == 'mass_harvests'">
              <div class="ic"><img :alt="feed.name" src="/images/setup/setup_icons-18.svg"></div>
            </template>
            <template v-else-if="feed.type == 'shorts'">
              <i class="icon-shorts"></i>
            </template>
            <template v-else-if="feed.type">
              <!-- <div class="ic"><i :class="'icon-feed-' + feed.type.replace('_','-').replace('_','-').replace('_','-')"></i></div>             -->
            </template>

            <a class="name" :href="feed.link" onclick="FeedManager.click('locale_ru','1')">{{ feed.lng_key ?
              $t(feed.lng_key) : feed.name }}</a>

            <div class="hint">{{ feed.hint ? $t(feed.hint) : '' }}</div>
            <div class="hidden_block hidden" onclick="FeedManager.hideGroup('locale_ru')">
              <i class="icon remove"></i>
            </div>
          </div>


          <template v-if="feed.items_list.items_diary?.length">
            <HorizontalScroll
              :arrows="true"
              :arrows-show-hover="true"
              :platform="'mobile'"
              >
              <GeneralShowMoreFeed 
                :platform="feed.items_list.items_diary.length > 8 && feed.id == 'recommended' ? 'desktop' : 'none'"
                :max-height="feed.id == 'recommended' ? 220*2 : 220"
                >
                <div class="report_boxs horizontal_mobile data">
                  <GeneralDiary v-for="(diary, diary_key) in feed.items_list.items_diary" :key="diary_key" :data="diary">
                  </GeneralDiary>
                </div>
              </GeneralShowMoreFeed>
            </HorizontalScroll>
          </template>

          <HorizontalScroll
          v-else-if="feed.items_list.items_grow_question?.length"
              :arrows="true"
              :arrows-show-hover="true"
              :platform="'mobile'"
              >
            <div               
              class="questionlist">
              <QuestionBox v-for="(opt, ind) in feed.items_list.items_grow_question.slice(0, 4)" :key="ind" :data="opt"
                :item_user="opt.item_user" 
                :is_resolved="opt.is_resolved" 
                :text="formatCroppedText(opt.text, 70)" 
                :title="opt.title"
                :link="opt.link" 
                :items_problem_photo="opt.items_problem_photo" 
                :item_week="opt.item_week"
                :items_problem_symptom="opt.items_problem_symptom" 
                :add_date="opt.add_date"
                />
            </div>
          </HorizontalScroll>


          <div 
            v-else-if="feed.items_list.items_open_contest?.length"
            class="contestlist">
            <GiveawayBoxCompact v-for="(opt, ind) in feed.items_list.items_open_contest" 
              :key="ind"
              :name="opt.short_name" 
              :link="opt.link" 
              :cover="opt.cover_back_small" 
              />
          </div>

          <FeedVideos v-else-if="feed.items_list.items_video?.length" 
            :list="feed.items_list.items_video" 
            /> 

          <!-- {{ feed.items_list.items_grow_question }} -->


        </div>

      </div>



    </template>

    <client-only>        
      <InfinityScroll 
        v-if="is_ended == false"
        :is-loading="is_loading"
        ref="el_infinity_scroll"/>
    </client-only>

  </div>
</template>


<script setup>


import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { $api, $ga, $adv, hook } = useNuxtApp()


const start = ref(0)
const limit = ref(6)
const is_ended = ref(false)
const el_infinity_scroll = ref(null)
const showed_more = ref(false)
const selected_filter = ref('all')


const toggleGroup = (id, show) => {
  showed_more.value = show ? id : false;
}

const chooseTag = (tag) => {
  start.value = 0;
  feed_data.value.items_feed = null;
  selected_filter.value = tag.filter;
  $ga.clickFeedTag(tag.filter);
}



const loaded_groups = computed(() => {
  if(start.value == 0){
    return [];
  }else{
    return (feed_data?.value?.items_feed.map((item) => item.id) ?? []).join(',');
  }
})
  

const loadData = async function() {
  // console.log('sdfsdf');
  // console.log(start.value);
  // console.log(limit.value);
  // console.log(loaded_groups.value);
  // console.log(selected_filter.value);

  const response = await $api.getFeed({
    start: start.value,
    limit: limit.value,
    loaded: loaded_groups.value,
    filter: selected_filter.value
  })  
  return response;  
}


const { pending: is_loading,  data: feed_data, refresh: refreshData } = await useLazyAsyncData('feed_data', async () => await loadData())

// items_tags.value = feed_data.value?.items_tags ?? [];




watchArray([start, selected_filter], async ([new_start, new_selected_filter], added, removed) => {
  // console.log('watcher');

  if(new_start == 0){
    feed_data.value.items_feed = [];
    is_ended.value = false;
  } 
 

  // $patcher.change('/explore', {
  //   tags: new_tags_selected,
  //   sort: new_sort,
  // });
  
  is_loading.value = true;
  var dt = await loadData();

  if(dt.items_feed?.length == 0)
    is_ended.value = true;  


  feed_data.value.items_feed = [...feed_data.value.items_feed, ...dt.items_feed]
 


  is_loading.value = false;


  // hooks.callHook('page:ready');

  
  // $adv.apply();


}, {deep: true})

// const router = useRouter();
// watch(router, async () => {
//   console.log(111);
//   refreshData();
// });



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

// onMounted(() => {
//   hook('nuxtlink:click:feed', () => {
//     console.log('feed:refresh');
//     refreshData();
//   })
// })
// onBeforeUnmount(() => {
//   hook('nuxtlink:click:feed', null)
// })


useHead(useNuxtApp().$head.getFeed());


onUnmounted(() => {
  feed_data.value = null;
})

// onMounted(() => {

//   $adv.apply();

// })

</script>






<style scoped>
.index_page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hidden_feed_data {
  display: none;
}

.feed_items {}

.feed_items .row_item {
  margin-bottom: 5px;
}

.feed_items .row_item>.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.feed_items .row_item>.header .ui.button {
  padding: 7px 20px;
}

.feed_items .row_item .header>.ic {
  display: flex;
  width: 42px;
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
}

.feed_items .row_item .header>.ic img {
  width: 100%;
  border-radius: 3px;
}

.feed_items .row_item .header>.ic img.user {
  border-radius: 100px;
}

.feed_items .row_item .header>.ic .icon_custom {
  font-size: 1.5rem;
}

.feed_items .row_item .header>.ic i {
  font-size: 1.2rem;
  width: 24px;
  height: 24px;
}

.feed_items .row_item .header>.ic .icon-trophy {
  color: #f6c70b;
}

.feed_items .row_item .header>.name {
  font-weight: bold;
  font-size: 1.1rem;
  display: inline-block;
}

.feed_items .row_item .header>.hint {
  color: gray;
  margin-top: 3px;
}

.feed_items .row_item .header>.hidden_block {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 2px;
  color: gray;
  cursor: pointer;
}

.feed_items .touch_slider .shadow_right,
.feed_items .touch_slider .shadow_left {
  top: 30px;
}

.feed_items .touch_slider_scroll {
  text-align: left;
}


.ui.loading.button {
  width: calc(100% - 20px);
}

@container pb (max-width: 768px) {
  .feed_items .row_item {
    /* margin-bottom: 1rem; */
  }
}

/* SHOW MORE*/

.show_more {
  width: 100%;
}

.show_more .show_more_content {
  max-height: 213px;
  overflow: hidden;
  flex-flow: wrap;
  margin-right: -20px;
  width: 100%;
  display: flex;
}

[data-type-id="recommended"] .show_more .show_more_content {
  max-height: 442px;
}

.show_more.active .show_more_content {
  max-height: 100%;
  overflow: hidden;
}

.show_more .show_more_btns {
  display: block;
  color: #1f1f1f;
  text-align: right;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 12px;
  cursor: pointer;
}

.recommended .show_more.enable .show_more_btns,
.show_more.enable:hover .show_more_btns {
  opacity: 1;
}

.show_more .show_more_btns>.more_item {
  display: none;
  background-color: white;
  padding: 5px 23px 5px 10px;
  border-radius: 3px;
}

.show_more .show_more_btns .btn_more {
  display: inline-block;
}

.show_more.active .show_more_btns .btn_less {
  display: inline-block;
}

.follow_btn {
  position: absolute;
  right: 40px;

}

/* MOBILE */


@media (max-width: 648px) {
  .show_more .show_more_content {
    max-height: 190px;
  }

  .show_more.active .show_more_content {
    max-height: 100%;
  }
}


@media (max-width: 500px) {
  .show_more .show_more_content {
    max-height: 220px;
  }

  .feed_items .row_item .header>.hint {
    display: none;
  }
}


@media (max-width: 400px) {
  .show_more .show_more_content {
    max-height: 193px;
  }

  .show_more.active .show_more_content {
    max-height: 100%;
  }
}


@media (max-width: 360px) {
  .show_more .show_more_content {
    max-height: 372px;
  }
}

@media (max-width: 310px) {
  .show_more .show_more_content {
    max-height: 189px;
  }
}






@media only screen and (max-width: 648px) {
  .recommended .show_more .show_more_content {
    max-height: 408px;
  }
}


@media only screen and (max-width: 600px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }
}

@media only screen and (max-width: 530px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }
}

@media only screen and (max-width: 500px) {
  .recommended .show_more .show_more_content {
    max-height: 454px;
  }
}

@media only screen and (max-width: 400px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }

}





/* contest */

.contestlist {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  flex-wrap: wrap;
  height: 290px;
  justify-content: left;
}

@container pb (max-width: 768px) {
  .contestlist {
    flex-wrap: nowrap;
  }
}

/* questionlist */

.questionlist {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.questionlist:deep(.question_box){
  max-width: calc(25% - 0.5rem);
}
.questionlist:deep(.question_box .data .ttl){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.questionlist:deep(.question_box .data){
  height: 100%;
}
.questionlist:deep(.question_box .glr){  
  margin: auto;
  margin-top: 10px;
}

@container pb (max-width: 956px) {

  .questionlist:deep(.question_box ){
    max-width: calc(33% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+4)) {
    display: none;
  }

}

@container pb (max-width: 756px) {

  .questionlist:deep(.question_box ){
    max-width: calc(50% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+4)) {
    display: none;
  }

}

@container pb (max-width: 520px) {

  .questionlist {
    flex-wrap: nowrap;
  } 
  .questionlist:deep(.question_box ){
    max-width: calc(70% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+3)) {
    display: inline-block;
  }

  /* .questionlist {}
  .questionlist > div,
  .questionlist .question_box {
    max-width: 100%;
    width: 100%;
  } */
}
</style>
