<template>
  <div>

    <div class="ttl skl"></div>

    <div class="its">
      <SkDiary 
        v-for="(o, k) in [0,1,2,3,4,5,6,7,8,9,10]"
        :key="k"
        />              
    </div>
  
  </div>
</template>


<script>
  
export default {
  components: {    
 
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.ttl{  
  width: 250px;
  height: 30px;  
  margin-bottom: 10px;  
}
.its{
  display: flex;
  flex-wrap: wrap;
  height: 185px;
  width: calc(100% + 20px);
  overflow-y: hidden;
  margin-bottom: 30px;  
} 



</style>
